import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { green, pink, yellow } from "@mui/material/colors";
import { useState } from "react";
import { GetDateTimeVietnamese } from "../../../../common/DateTimeCalc";
import useHTTP from "../../../../common/useHTTP";
import APIEndpoint from "../../../http/APIEndpoint";
import CommonModal from "../../../ui/CommonModal";
import ConfirmModal from "../../../ui/ConfirmModal";
import AddPayment from "./AddPayment";

const BillCell = (props) => {
  return (
    <Typography
      variant="h6"
      sx={{
        border: "0.5px solid black",
        paddingInline: 1,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default function EditBillModal(props) {
  const billAPI = useHTTP();
  const paymentAPI = useHTTP();
  const { onClose, bill } = props;
  const [openPayment, setOpenPayment] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDetailPayment, setOpenDetailPayment] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  function handleAddPayment() {
    setOpenPayment(true);
  }
  async function handleDeleteBill() {
    try {
      const response = await billAPI.delete(
        APIEndpoint.BILL_V1(bill.customer_id, bill.bill_id)
      );
      console.log(response);
      alert("Xóa hóa đơn thành công");
      onClose();
    } catch {
      alert("Không xóa được hóa đơn");
    }
  }
  async function addPaymentCB(amount) {
    try {
      console.log(amount);
      if (amount === 0) return;
      let response = await paymentAPI.post(
        APIEndpoint.PAYMENT_V1(bill.customer_id, bill.bill_id),
        {
          amount: amount,
        }
      );
      console.log(response);
      alert(
        `Thêm thanh toán thành công \n${bill.bill_name} - Số Tiền : ${amount}`
      );
      onClose();
    } catch {
      alert("Thêm thanh toán thất bại");
    }
  }
  return (
    <CommonModal open={bill} onClose={onClose}>
      {bill && (
        <Stack px={3} spacing={1.5}>
          <Typography variant="h3" color="secondary" textAlign="center">
            {bill.bill_name}
          </Typography>

          {/* Bill Summary */}
          <Box
            sx={{ backgroundColor: yellow[100], padding: 1, borderRadius: 2 }}
            onClick={() => {
              setOpenDetail(!openDetail);
            }}
          >
            <Typography variant="h4">Tổng : {bill.total}</Typography>
            {openDetail && (
              <Stack ml={3}>
                {/* Phòng */}
                <Grid container>
                  <Grid item xs={6}>
                    <BillCell>{`Tiền Phòng`}</BillCell>
                  </Grid>
                  <Grid item xs={3}>
                    <BillCell></BillCell>
                  </Grid>
                  <Grid item xs={3}>
                    <BillCell>{bill.rent}</BillCell>
                  </Grid>
                </Grid>

                {/* Nước */}
                <Grid container>
                  <Grid item xs={6}>
                    <BillCell>{`Tiền Nước`}</BillCell>
                  </Grid>
                  <Grid item xs={3}>
                    <BillCell>{bill.water.note}</BillCell>
                  </Grid>
                  <Grid item xs={3}>
                    <BillCell>{bill.water.amount}</BillCell>
                  </Grid>
                </Grid>

                {/* Điện */}
                <Grid container>
                  <Grid item xs={6}>
                    <BillCell>{`Tiền Điện`}</BillCell>
                  </Grid>
                  <Grid item xs={3}>
                    <BillCell>{bill.electric.note}</BillCell>
                  </Grid>
                  <Grid item xs={3}>
                    <BillCell>{bill.electric.amount}</BillCell>
                  </Grid>
                </Grid>

                {/* Khác */}
                <Grid container>
                  <Grid item xs={6}>
                    <BillCell>{`Tiền Khác`}</BillCell>
                  </Grid>
                  <Grid item xs={3}>
                    <BillCell>{bill.other_fee.note}</BillCell>
                  </Grid>
                  <Grid item xs={3}>
                    <BillCell>{bill.other_fee.amount}</BillCell>
                  </Grid>
                </Grid>
              </Stack>
            )}
          </Box>

          <Box
            sx={{ backgroundColor: green[100], padding: 1, borderRadius: 2 }}
            onClick={() => setOpenDetailPayment(!openDetailPayment)}
          >
            <Typography variant="h4">Đã TT : {bill.paid}</Typography>
            {openDetailPayment && (
              <Stack>
                {bill.payments.map((paid) => {
                  return (
                    <Box sx={{}} my={1}>
                      <Typography variant="h6">
                        {`${GetDateTimeVietnamese(paid.created_at)}`}
                      </Typography>
                      <Typography variant="h5">{`Đã Trả : ${paid.amount}`}</Typography>
                    </Box>
                  );
                })}
              </Stack>
            )}
          </Box>
          <Box sx={{ backgroundColor: pink[100], padding: 1, borderRadius: 2 }}>
            <Typography variant="h4">Còn : {bill.remain}</Typography>
          </Box>
          <Button
            variant="outlined"
            size="large"
            color="success"
            onClick={handleAddPayment}
          >
            Thêm Thanh Toán
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="error"
            onClick={() => setOpenDeleteModal(true)}
          >
            Xóa Hóa Đơn
          </Button>
        </Stack>
      )}
      <AddPayment
        open={openPayment}
        setOpen={setOpenPayment}
        addPaymentCB={addPaymentCB}
      />
      <ConfirmModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onAccept={handleDeleteBill}
      >
        <Typography variant="h6">Bấm đồng ý để xóa hóa đơn</Typography>
      </ConfirmModal>
    </CommonModal>
  );
}
