import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useHTTP from "../../../common/useHTTP";
import APIEndpoint from "../../http/APIEndpoint";
import Bill from "./Bill";
import Profile from "./Profile";
// import Trade from "./Trade";

export default function CustomerDetail() {
  const { customer_id } = useParams();
  const customerAPI = useHTTP();
  console.log(customer_id);
  const [customer, setCustomer] = useState({});
  useEffect(() => {
    async function getCustomer() {
      const response = await customerAPI.get(
        APIEndpoint.CUSTOMER_V1(customer_id)
      );
      if (response) setCustomer(response.content[0]);
    }
    getCustomer();
  }, [customer_id]);
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Profile customer={customer} setCustomer={setCustomer} />
        </Grid>
        <Grid item xs={12} sm={8} marginBottom={5}>
          <Bill customerId={customer_id} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
