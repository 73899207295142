import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import Navbar from "./component/navbar";
import LoadingModal from "./component/ui/LoadingModal";

function Layout(props) {
  const isLoading = useSelector((redux) => redux.loading.isLoading);
  return (
    <Box>
      <Navbar />
      {props.children}
      <LoadingModal isLoading={isLoading} />
    </Box>
  );
}

export default Layout;
