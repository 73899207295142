import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Fragment } from "react";
import CountDays from "../../../../common/DateTimeCalc";
const StyledContainer = styled(Grid)({
  marginBlock: 5,
  height: 45,
  borderRadius: 5,
  borderWidth: 1,
});

const StyledGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 18,
  fontWeight: 700,
  borderRight: "0.1px solid gray",
});

function BillItem(props) {
  const { bill, index } = props;
  // console.log(bill);
  let countDays = CountDays(bill.created_at);
  function handleClickBill() {
    props.handleClickBill(bill);
  }
  return (
    <Fragment>
      <StyledContainer
        container
        sx={{
          backgroundColor: index % 2 === 0 ? grey[100] : "inherit",
          cursor: "pointer",
        }}
        onClick={handleClickBill}
      >
        <StyledGrid item xs={3} md={2}>
          {bill.bill_name}
        </StyledGrid>
        {/* Phòng */}
        <StyledGrid item md={1} sx={{ display: { xs: "none", md: "flex" } }}>
          {bill.rent}
        </StyledGrid>
        {/* Nước */}
        <StyledGrid item md={2} sx={{ display: { xs: "none", md: "flex" } }}>
          {bill.water.note + " - " + bill.water.amount}
        </StyledGrid>
        {/* Điện */}
        <StyledGrid item md={2} sx={{ display: { xs: "none", md: "flex" } }}>
          {bill.electric.note + " - " + bill.electric.amount}
        </StyledGrid>
        {/* Khác */}
        <StyledGrid item md={2} sx={{ display: { xs: "none", md: "flex" } }}>
          {bill.other_fee.note + " - " + bill.other_fee.amount}
        </StyledGrid>
        {/* Tổng */}
        <StyledGrid item xs={3} md={1}>
          {bill.total}
        </StyledGrid>
        {/* Đã TT */}
        <StyledGrid item xs={3} md={1}>
          {bill.paid}
        </StyledGrid>
        {/* Còn */}
        <StyledGrid item xs={3} md={1}>
          {bill.remain}
        </StyledGrid>
      </StyledContainer>
    </Fragment>
  );
}

export default BillItem;
