import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { Fragment } from "react";

const StyledContainer = styled(Grid)({
  marginBlock: 10,
  backgroundColor: blue[200],
  height: 45,
  borderRadius: 5,
});

const StyledGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 20,
  fontWeight: 700,
  borderRight: "1px solid gray",
});

function Header() {
  return (
    <Fragment>
      <StyledContainer container>
        <StyledGrid item xs={3} md={2}>
          Tháng
        </StyledGrid>
        <StyledGrid item md={1} sx={{ display: { xs: "none", md: "flex" } }}>
          Phòng
        </StyledGrid>
        <StyledGrid item md={2} sx={{ display: { xs: "none", md: "flex" } }}>
          Nước
        </StyledGrid>
        <StyledGrid item md={2} sx={{ display: { xs: "none", md: "flex" } }}>
          Điện
        </StyledGrid>
        <StyledGrid item md={2} sx={{ display: { xs: "none", md: "flex" } }}>
          Khác
        </StyledGrid>
        <StyledGrid item xs={3} md={1}>
          Tổng
        </StyledGrid>
        <StyledGrid item xs={3} md={1}>
          Đã TT
        </StyledGrid>
        <StyledGrid item xs={3} md={1}>
          Còn
        </StyledGrid>
      </StyledContainer>
    </Fragment>
  );
}

export default Header;
