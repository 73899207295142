import { useState } from "react";

export default function useInput(
  initialValue,
  regex = "",
  errorText = "Dữ liệu không hợp lệ"
) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (regex != "" && !regex.test(event.target.value)) {
      setError(errorText);
    } else {
      setError(null);
    }
  };

  return {
    value,
    error,
    onChange: handleChange,
  };
}
