import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import homeLogo from "../../../static/image/icon/home.png";
import CommonModal from "../../ui/CommonModal";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";

function Login(props) {
  const { setOpen, open } = props;
  const [isSignUp, setSignUp] = React.useState(false);
  return (
    <CommonModal width={350} open={open} setOpen={setOpen} closeButton={false}>
      <Box justifyContent="center" display="flex">
        <img src={homeLogo} height={120} width={120} />
      </Box>

      <Typography variant="h3" textAlign="center">
        {!isSignUp ? "Welcome" : "Sign up"}
      </Typography>
      <Divider />
      {!isSignUp && <LoginForm setSignUp={setSignUp} />}
      {isSignUp && <SignUpForm setSignUp={setSignUp} />}
    </CommonModal>
  );
}

export default Login;
