import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import AWS from "aws-sdk";
import { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authAction } from "../../../store/AuthSlice";
import authAPI from "../../http/AuthAPI";
import { setupToken } from "../../http/axiosClient";

function LoginForm(props) {
  const { setSignUp } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fromPath = location?.state?.from?.pathname;

  async function signIn() {
    try {
      let response = await authAPI.signIn(username, password);
      console.log(response);
      dispatch(authAction.setAuth({ ...response }));
      setupToken({ accessToken: response.IdToken });
      alert("Đăng nhập thành công");
      fromPath ? navigate(fromPath) : navigate("/customer");
    } catch (e) {
      alert("Sai tài khoản hoặc mật khẩu");
    }
  }
  function responseFacebook(res) {
    // Add the Facebook access token to the Amazon Cognito credentials login map.
    AWS.config.update({ region: process.env.REACT_APP_COGNITO_REGION });
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      Logins: {
        "graph.facebook.com": res.accessToken,
      },
    });
    // Make the call to obtain credentials
    AWS.config.credentials.get(function () {
      // Credentials will be available when this function is called.
      var accessKeyId = AWS.config.credentials.accessKeyId;
      var secretAccessKey = AWS.config.credentials.secretAccessKey;
      var sessionToken = AWS.config.credentials.sessionToken;
      console.log(AWS.config.credentials);
      console.log(AWS.config.credentials.identityId);
      console.log(sessionToken);
      // navigate("/callback-login?code=" + accessKeyId);
      // Initialize the CognitoIdentity object
      const cognitoIdentity = new AWS.CognitoIdentity({
        region: process.env.REACT_APP_COGNITO_REGION,
      });
    });
  }
  return (
    <Box marginTop={5} display="flex" justifyContent="center">
      <Stack spacing={3} sx={{ width: "80%" }}>
        <TextField
          id="input-with-icon-textfield"
          placeholder="Tài khoản"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircleOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          id="input-with-icon-textfield"
          placeholder="Mật khẩu"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* <Button
          onClick={(e) => {
            console.log(customerAPI.getCustomers());
          }}
        >
          Test
        </Button> */}
        <Button
          variant="contained"
          onClick={() => {
            signIn();
          }}
        >
          Sign in
        </Button>
        <Box display="flex" justifyContent="space-evenly">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID}
            // autoLoad
            callback={responseFacebook}
            render={(renderProps) => (
              <img
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Facebook_f_logo_%282019%29.svg/2048px-Facebook_f_logo_%282019%29.svg.png"
                }
                width={50}
                height={50}
                onClick={renderProps.onClick}
                style={{ cursor: "pointer" }}
              />
            )}
          />
          <img
            src={"https://blog.hubspot.com/hubfs/image8-2.jpg"}
            width={50}
            height={50}
          />
        </Box>
        <Button
          display="flex"
          variant="outlined"
          onClick={() => {
            setSignUp(true);
          }}
        >
          Sign up
        </Button>
      </Stack>
    </Box>
  );
}

export default LoginForm;
