import { Fragment, useEffect, useState } from "react";
import AddBillModal from "./AddBillModal";
import BillItem from "./BillItem";
import EditBillModal from "./EditBillModal";
import { Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useHTTP from "../../../../common/useHTTP";
import APIEndpoint from "../../../http/APIEndpoint";

function ListBill(props) {
  const { customerId } = props;
  const billAPI = useHTTP();
  const [listBill, setListBill] = useState([]);
  const [billModal, setBillModal] = useState(null);
  const [addBillModal, setAddBillModal] = useState(false);
  useEffect(() => {
    getBills();
  }, [customerId]);

  async function getBills() {
    let response = await billAPI.get(APIEndpoint.BILL_V1(customerId));
    setListBill(response.content);
    console.log(response.content);
  }

  function handleClickBill(bill) {
    console.log(bill);
    setBillModal(bill);
  }

  function closeEditBillModal() {
    getBills();
    setBillModal(null);
  }

  function closeAddBillModal() {
    getBills();
    setAddBillModal(false);
  }
  return (
    <Box sx={{ height: 400 }}>
      {listBill.map((bill, index) => {
        return (
          <BillItem
            bill={bill}
            key={bill.bill_id}
            index={index}
            handleClickBill={handleClickBill}
          />
        );
      })}
      <EditBillModal bill={billModal} onClose={closeEditBillModal} />
      <AddBillModal
        open={addBillModal}
        setOpen={closeAddBillModal}
        customerId={customerId}
      />
      <Fab
        color="success"
        aria-label="add"
        sx={{ position: "fixed", bottom: "20px", right: "20px" }}
        onClick={() => setAddBillModal(true)}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
}

export default ListBill;
