import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetDateTimeVietnamese } from "../../../common/DateTimeCalc";
import useHTTP from "../../../common/useHTTP";
import { customerAction } from "../../../store/CustomerSlice";
import APIEndpoint from "../../http/APIEndpoint";
import ConfirmModal from "../../ui/ConfirmModal";

export default function Profile({ customer, setCustomer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerAPI = useHTTP();

  function handleChangeCustomer(field, value) {
    setCustomer({ ...customer, [field]: value });
  }
  async function handleUpdateCustomer() {
    let data = {
      ...customer,
    };
    let response = await customerAPI.put(
      APIEndpoint.CUSTOMER_V1(customer.customer_id),
      data
    );
    if (response) setCustomer(response.content);
    alert("Đã thay đổi thông tin khách hàng");
  }

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  async function handleDeleteCustomer() {
    try {
      let response = await customerAPI.delete(
        APIEndpoint.CUSTOMER_V1(customer.customer_id)
      );
      const removeCustomerId = response;
      console.log(removeCustomerId);
      if (response) {
        dispatch(customerAction.deleteCustomer(removeCustomerId));
      }
      alert("Xóa khách hàng thành công");
      navigate("/customer");
    } catch {
      alert("Không xóa được khách hàng");
      navigate("/customer");
    }
  }
  return (
    <Stack px={3} spacing={1.5} sx={{ overflow: "inherit" }}>
      <Typography variant="h3" color="secondary" textAlign="center" my={3}>
        Thông Tin
      </Typography>
      <TextField
        label="Tên Khách"
        InputLabelProps={{ shrink: true }}
        value={customer.customer_name}
        onChange={(e) => handleChangeCustomer("customer_name", e.target.value)}
      />
      <TextField
        label="Số Điện Thoại"
        InputLabelProps={{ shrink: true }}
        value={customer.phone}
        onChange={(e) => handleChangeCustomer("phone", e.target.value)}
      />
      <TextField
        label="Địa Chỉ"
        InputLabelProps={{ shrink: true }}
        value={customer.address}
        onChange={(e) => handleChangeCustomer("address", e.target.value)}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        label="Tên Phòng"
        value={customer.room_name}
        onChange={(e) => handleChangeCustomer("room_name", e.target.value)}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        label="Thanh Toán Gần Đây"
        value={GetDateTimeVietnamese(customer.last_pay_date)}
      />
      {/* <Stack flexDirection={"row"}> */}
      <Button
        variant="outlined"
        size="large"
        color="success"
        onClick={handleUpdateCustomer}
      >
        Lưu Thay Đổi
      </Button>
      <Button
        variant="outlined"
        size="large"
        color="error"
        onClick={() => setOpenDeleteModal(true)}
      >
        Xóa Khách Hàng
      </Button>
      <ConfirmModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onAccept={handleDeleteCustomer}
      >
        <Typography variant="h6">Bấm đồng ý để xóa khách hàng</Typography>
      </ConfirmModal>
    </Stack>
  );
}
