import axios from "axios";
import queryString from "query-string";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

function setupToken(auth) {
  axiosClient.interceptors.request.use(async (config) => {
    const { accessToken } = auth;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  });
}

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data.content;
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 400) {
      alert("Lỗi đăng nhập");
    }
    console.log(error);
    throw error;
  }
);

export default axiosClient;
export { setupToken };
