import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useHTTP from "../../common/useHTTP";
import { customerAction } from "../../store/CustomerSlice";

export default function SearchBar() {
  const listCustomerRedux = useSelector((redux) => redux.customer.listCustomer);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const http = useHTTP("/api/v1/customers");
  function handlerChangeText(value) {
    setSearchText(value);
  }
  useEffect(() => {
    async function getCustomer() {
      const response = await http.get();
      if (response)
        dispatch(
          customerAction.setListCustomer({ listCustomer: response.content })
        );
    }
    getCustomer();
  }, []);
  return (
    <Stack spacing={2} sx={{ mx: 1 }}>
      <Autocomplete
        selectOnFocus
        sx={{ width: 220 }}
        options={listCustomerRedux.map((option) => option)}
        getOptionLabel={(option) => option.customer_name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tìm Khách Hàng"
            value={searchText}
            onChange={(e) => {
              handlerChangeText(e);
            }}
          />
        )}
        onChange={(e, value) => {
          handlerChangeText(value);
          navigate(`/customer/${value.customer_id}`);
        }}
      />
    </Stack>
  );
}
