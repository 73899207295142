import styled from "@emotion/styled";
import { CircularProgress, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

const StyledBox = styled(Box)({
  width: 170,
  height: 170,
  borderRadius: 15,
  padding: 10,
  opacity: 1,
  backgroundColor: "black",
});

const LoadingModal = (props) => {
  const { isLoading } = props;
  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={isLoading}
    >
      <StyledBox>
        <Typography textAlign="center" variant="h4" color="chocolate">
          Đang Tải
        </Typography>
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress color="secondary" size={60} />
        </Box>
      </StyledBox>
    </Modal>
  );
};

export default LoadingModal;
