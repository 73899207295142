import { configureStore } from "@reduxjs/toolkit";
import CustomerSlice from "./CustomerSlice";
import AuthSlice from "./AuthSlice";
import SearchSlice from "./SearchSlice";
import BillSlice from "./BillSlice";
import LoadingSlice from "./LoadingSlice";

const store = configureStore({
  reducer: {
    auth: AuthSlice.reducer,
    customer: CustomerSlice.reducer,
    search: SearchSlice.reducer,
    trade: BillSlice.reducer,
    loading: LoadingSlice.reducer,
  },
});

export default store;
