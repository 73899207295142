import { createSlice } from "@reduxjs/toolkit";

const InitialState = {
  isLogin: false,
  accessToken: null,
  idToken: null,
  refreshToken: null,
  user: {},
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: InitialState,
  reducers: {
    setAuth(state, action) {
      const { AccessToken, IdToken, RefreshToken, User } = action.payload;
      state.accessToken = AccessToken;
      state.idToken = IdToken;
      state.refreshToken = RefreshToken;
      state.user = User;
      state.isLogin = true;
    },
    clearAuth(state, action) {
      state = InitialState;
    },
  },
});

export const authAction = AuthSlice.actions;
export default AuthSlice;
