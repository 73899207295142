import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CountDays from "../../common/DateTimeCalc";
import useHTTP from "../../common/useHTTP";
import { customerAction } from "../../store/CustomerSlice";
import APIEndpoint from "../http/APIEndpoint";
import customerAPI from "../http/CustomerAPI";
import CustomerDetail from "./customer";

const StyledContainer = styled(Grid)({
  marginBlock: 5,
  height: 45,
  borderRadius: 5,
  borderWidth: 1,
  cursor: "pointer",
});

const StyledGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  paddingInline: 3,
  fontSize: 20,
  fontWeight: 700,
  borderRight: "0.1px solid gray",
});

function Content() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listCustomer = useSelector((redux) => redux.customer.listCustomer);
  const http = useHTTP();
  useEffect(() => {
    async function getCustomers() {
      const response = await http.get(APIEndpoint.CUSTOMER_V1());
      dispatch(
        customerAction.setListCustomer({ listCustomer: response.content })
      );
    }
    getCustomers();
  }, []);

  //Customer Detail Modal
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedCustomer, selectCustomer] = useState();
  function handleSelectCustomer(customer) {
    navigate(`/customer/${customer.customer_id}`);
  }
  function handleCloseModal() {
    selectCustomer(null);
  }
  return (
    <Fragment>
      {selectedCustomer && (
        <CustomerDetail
          open={openDetail}
          setOpen={setOpenDetail}
          customer={selectedCustomer}
          onClose={handleCloseModal}
        />
      )}
      <StyledContainer
        container
        sx={{ position: "sticky", backgroundColor: blue[200] }}
      >
        <StyledGrid item xs={6} sm={3}>
          Tên Khách
        </StyledGrid>
        <StyledGrid item xs={3} sm={2}>
          Tên Phòng
        </StyledGrid>
        <StyledGrid
          item
          xs={3}
          sm={3}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          Số ĐT
        </StyledGrid>
        <StyledGrid
          item
          xs={3}
          sm={3}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          Địa chỉ
        </StyledGrid>
        <StyledGrid item xs={3} sm={1}>
          Thời Gian
        </StyledGrid>
      </StyledContainer>
      <StyledContainer>
        {listCustomer.map((customer, index) => {
          // const linkImage = require("../../static/image/icon/".concat(
          //   customer.image
          // ));
          return (
            <StyledContainer
              container
              key={customer.customer_id}
              onClick={() => handleSelectCustomer(customer)}
              backgroundColor={index % 2 != 0 ? "inherit" : grey[100]}
            >
              <StyledGrid item xs={6} sm={3}>
                {/* <Avatar src={linkImage} /> */}
                <Typography noWrap variant="h5">
                  {customer.customer_name}
                </Typography>
              </StyledGrid>
              <StyledGrid item xs={3} sm={2}>
                <Typography variant="h5">{customer.room_name}</Typography>
              </StyledGrid>
              <StyledGrid
                item
                sx={{ display: { xs: "none", sm: "flex" } }}
                sm={3}
              >
                <Typography noWrap variant="h5">
                  {customer.phone}
                </Typography>
              </StyledGrid>
              <StyledGrid
                item
                sx={{ display: { xs: "none", sm: "flex" } }}
                sm={3}
              >
                <Typography noWrap variant="h5">
                  {customer.address}
                </Typography>
              </StyledGrid>
              <StyledGrid item xs={3} sm={1}>
                <Typography variant="h5">
                  {CountDays(customer.last_pay_date)}
                </Typography>
              </StyledGrid>
            </StyledContainer>
          );
        })}
      </StyledContainer>
    </Fragment>
  );
}

export default Content;
