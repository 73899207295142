import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GetMonthYearVietnamese } from "../../../../common/DateTimeCalc";
import useHTTP from "../../../../common/useHTTP";
import useInput from "../../../../common/useInput";
import APIEndpoint from "../../../http/APIEndpoint";
import CommonModal from "../../../ui/CommonModal";

export default function AddBillModal(props) {
  const { customerId, open, setOpen } = props;
  const billAPI = useHTTP();
  const billNameRegex = /^.{1,20}$/;
  const billName = useInput(
    GetMonthYearVietnamese(),
    billNameRegex,
    "Tên hóa đơn có độ dài từ 1-20 kí tự"
  );
  const rent = useInput(0);
  const waterAmount = useInput(0);
  const waterPrice = useInput(0);
  const electricAmount = useInput(0);
  const electricPrice = useInput(0);
  const otherAmount = useInput(0);
  const otherPrice = useInput(0);

  async function handleAddBill() {
    try {
      const newBill = {
        bill_name: billName.value,
        rent: rent.value,
        water: { note: waterAmount.value, amount: waterPrice.value },
        electric: { note: electricAmount.value, amount: electricPrice.value },
        other_fee: { note: otherAmount.value, amount: otherPrice.value },
      };
      const response = await billAPI.post(
        APIEndpoint.BILL_V1(customerId),
        newBill
      );
      console.log(response);
      alert("Thêm Hóa Đơn Thành Công");
      setOpen(false);
    } catch {
      alert("Thêm Hóa Đơn Thất Bại");
    }
  }
  return (
    <CommonModal open={open} setOpen={setOpen}>
      <Stack
        spacing={2}
        onFocus={(e) => {
          try {
            e.target.select();
          } catch {}
        }}
        padding={2}
      >
        <Typography variant="h3" color="secondary" textAlign="center">
          Thêm Hóa Đơn
        </Typography>
        <TextField
          label="Tên Hóa Đơn"
          InputLabelProps={{ shrink: true, style: { fontSize: 25 } }}
          InputProps={{ style: { fontSize: 25 } }}
          fullWidth
          value={billName.value}
          onChange={billName.onChange}
          error={billName.error !== null}
          helperText={billName.error}
        />
        <TextField
          label="Tiền Phòng"
          InputLabelProps={{ shrink: true, style: { fontSize: 25 } }}
          InputProps={{
            style: { fontSize: 25 },
            endAdornment: <InputAdornment position="end">k</InputAdornment>,
          }}
          fullWidth
          type="number"
          value={rent.value}
          onChange={rent.onChange}
        />
        {/* Water Price */}
        <Box display="flex" flexDirection="row">
          <TextField
            label="Khối Nước"
            InputLabelProps={{ shrink: true, style: { fontSize: 25 } }}
            InputProps={{
              style: { fontSize: 25 },
              endAdornment: <InputAdornment position="end">kl</InputAdornment>,
            }}
            fullWidth
            value={waterAmount.value}
            onChange={waterAmount.onChange}
          />
          <TextField
            label="Tiền Nước"
            InputLabelProps={{ shrink: true, style: { fontSize: 25 } }}
            InputProps={{
              style: { fontSize: 25 },
              endAdornment: <InputAdornment position="end">k</InputAdornment>,
            }}
            fullWidth
            type="number"
            value={waterPrice.value}
            onChange={waterPrice.onChange}
          />
        </Box>

        {/* Electric Price */}
        <Box display="flex" flexDirection="row">
          <TextField
            label="Số Điện"
            InputLabelProps={{ shrink: true, style: { fontSize: 25 } }}
            InputProps={{
              style: { fontSize: 25 },
              endAdornment: <InputAdornment position="end">kw</InputAdornment>,
            }}
            fullWidth
            value={electricAmount.value}
            onChange={electricAmount.onChange}
          />
          <TextField
            label="Tiền Điện"
            InputLabelProps={{ shrink: true, style: { fontSize: 25 } }}
            InputProps={{
              style: { fontSize: 25 },
              endAdornment: <InputAdornment position="end">k</InputAdornment>,
            }}
            fullWidth
            type="number"
            value={electricPrice.value}
            onChange={electricPrice.onChange}
          />
        </Box>

        {/* Other Price */}
        <Box display="flex" flexDirection="row">
          <TextField
            label="Ghi Chú"
            InputLabelProps={{ shrink: true, style: { fontSize: 25 } }}
            InputProps={{
              style: { fontSize: 25 },
            }}
            fullWidth
            value={otherAmount.value}
            onChange={otherAmount.onChange}
          />
          <TextField
            label="Giá"
            InputLabelProps={{ shrink: true, style: { fontSize: 25 } }}
            InputProps={{
              style: { fontSize: 25 },
              endAdornment: <InputAdornment position="end">k</InputAdornment>,
            }}
            fullWidth
            type="number"
            value={otherPrice.value}
            onChange={otherPrice.onChange}
          />
        </Box>
        <Button
          variant="outlined"
          size="large"
          color="success"
          onClick={handleAddBill}
        >
          Thêm Hóa Đơn
        </Button>
      </Stack>
    </CommonModal>
  );
}
