import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Customers from "../component/customers";
import CustomerDetail from "../component/customers/customer";
import Layout from "../Layout";

function CustomerPage() {
  return (
    <Fragment>
      <Layout>
        <Routes>
          <Route path="/" element={<Customers />} />
          <Route path="/:customer_id" element={<CustomerDetail />} />
        </Routes>
      </Layout>
    </Fragment>
  );
}
export default CustomerPage;
