import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CallBackLogin from "./component/auth/CallBackLogin";
import CustomerPage from "./page/CustomerPage";
import LoginPage from "./page/LoginPage";
import PayPage from "./page/PayPage";
import RequireAuth from "./page/RequireAuth";

function App() {
  return (
    <Fragment>
      <Routes>
        {/* Public */}
        <Route path="/" element={<Navigate to="login" />}></Route>
        {/* <Route path="/" element={<Navigate to="customer" />}></Route> */}
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/callback-login/*" element={<CallBackLogin />}></Route>

        {/* Authenticate */}
        <Route element={<RequireAuth />}>
          <Route path="/customer/*" element={<CustomerPage />}></Route>
          <Route path="/pay" element={<PayPage />}></Route>
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
