const APIEndpoint = {
  CUSTOMER_V1: (customer_id) =>
    customer_id ? `/api/v1/customers/${customer_id}` : `/api/v1/customers`,
  BILL_V1: (customer_id, bill_id) =>
    bill_id
      ? `/api/v1/customers/${customer_id}/bills/${bill_id}`
      : `/api/v1/customers/${customer_id}/bills`,
  PAYMENT_V1: (customer_id, bill_id, payment_id) =>
    payment_id
      ? `/api/v1/customers/${customer_id}/bills/${bill_id}/payments/${payment_id}`
      : `/api/v1/customers/${customer_id}/bills/${bill_id}/payments`,
};
export default APIEndpoint;
