import axiosClient from "./axiosClient";
import AWS from "aws-sdk";
const endPoint = "/api/v1/auth";
class AuthAPI {
  callbackLogin = (params) => {
    return axiosClient.get(endPoint + "/callback", { params });
  };

  signUp = async (username, password, email) => {
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();
    const params = {
      ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID_REACTJS,
      Password: password,
      Username: username,
      UserAttributes: [
        {
          Name: "email",
          Value: email,
        },
      ],
    };

    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.signUp(params, (err, data) => {
        if (err) reject(err);
        else resolve(data);
      });
    });
  };

  signIn = async (username, password) => {
    AWS.config.update({ region: process.env.REACT_APP_COGNITO_REGION });
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();
    const params = {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID_REACTJS,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
    };

    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.initiateAuth(params, (err, data) => {
        if (err) reject(err);
        else {
          resolve(data.AuthenticationResult);
        }
      });
    });
  };

  getUser = (accessToken) => {
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();
    const params = {
      AccessToken: accessToken,
    };

    cognitoidentityserviceprovider.getUser(params, (err, data) => {
      if (err) console.log(err);
      else console.log(data);
    });
  };
}
const authAPI = new AuthAPI();
export default authAPI;
