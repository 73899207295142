import { Stack, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import Header from "./Header";
import ListBill from "./ListBill";

function Bill({ customer, setCustomer, customerId }) {
  // const [showMode, setShowMode] = useState("all");
  return (
    <Fragment>
      <Stack px={3} spacing={1.5} sx={{ display: "block", overflow: "auto" }}>
        <Typography variant="h3" color="primary" textAlign="center" my={3}>
          Hóa Đơn
        </Typography>
        <Header />
        <ListBill customerId={customerId} />
      </Stack>
    </Fragment>
  );
}

export default Bill;
