import axiosClient from "./axiosClient";
const endPoint = "/api/v1/customers";
class CustomerAPI {
  getCustomer = (customer_id) => {
    return axiosClient.get(endPoint + `/${customer_id}`);
  };
  getCustomers = (params) => {
    return axiosClient.get(endPoint, { params });
  };
  addCustomer = (data) => {
    return axiosClient.post(endPoint, data);
  };
  updateCustomer = (data) => {
    return axiosClient.put(endPoint, data);
  };
  deleteCustomer = (data) => {
    return axiosClient.delete(endPoint, { data });
  };
}
const customerAPI = new CustomerAPI();
export default customerAPI;
