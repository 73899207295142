import { Typography } from "@mui/material";
import { Fragment } from "react";

function PayPage() {
  return (
    <Fragment>
      <Typography>PayPage</Typography>
    </Fragment>
  );
}
export default PayPage;
