import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingAction } from "../store/LoadingSlice";

const useHTTP = (endpoint) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const accessToken = useSelector((redux) => redux.auth.idToken);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  const sendRequest = async (method, url, body = null) => {
    try {
      dispatch(loadingAction.startLoading());
      let request = {
        method: method,
        headers: headers,
      };
      if (body !== null && body) request.body = JSON.stringify(body);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}${endpoint ? endpoint : ""}${
          url ? url : ""
        }`,
        request
      );
      dispatch(loadingAction.endLoading());
      return await res.json();
    } catch (err) {
      setError(err);
      dispatch(loadingAction.endLoading());
    }
  };

  return {
    get: (url = "") => sendRequest("GET", url),
    post: (url = "", body) => sendRequest("POST", url, body),
    put: (url = "", body) => sendRequest("PUT", url, body),
    delete: (url = "", body) => sendRequest("DELETE", url, body),
    error,
  };
};

export default useHTTP;
