import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import authAPI from "../http/AuthAPI";

// Callback component to handle the authorization code from Amazon Cognito
export default function CallbackLogin() {
  // Get the authorization code from the URL query parameter
  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  let code = searchParams.get("code");
  let navigate = useNavigate();
  //   Make a request to your FastAPI application to exchange the authorization code for tokens
  useEffect(() => {
    try {
      getToken(code);
    } catch {}
    navigate("/");
  }, [code]);

  async function getToken(code) {
    let tokens = await authAPI.callbackLogin({ code });
    localStorage.setItem("accessToken", tokens.access_token);
    localStorage.setItem("refreshToken", tokens.refresh_token);
    console.log(tokens);
  }

  return <div>Loading...</div>;
}
