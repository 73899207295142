import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import CommonModal from "../../../ui/CommonModal";

export default function AddPayment(props) {
  const { open, setOpen, addPaymentCB } = props;
  const [amount, setAmount] = useState(0);
  function addPayment() {
    addPaymentCB(amount);
    setOpen(false);
  }
  return (
    <CommonModal open={open} setOpen={setOpen}>
      <Stack px={3} spacing={3}>
        <Typography variant="h4" color="secondary" textAlign="center">
          Thêm Thanh Toán
        </Typography>
        <TextField
          label="Số Tiền"
          InputLabelProps={{ shrink: true, style: { fontSize: 25 } }}
          InputProps={{ style: { fontSize: 25 } }}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
          type="number"
          onFocus={(e) => {
            e.target.select();
          }}
        />
        <Button
          variant="outlined"
          size="large"
          color="success"
          onClick={addPayment}
        >
          Thêm Thanh Toán
        </Button>
      </Stack>
    </CommonModal>
  );
}
